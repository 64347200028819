body {
  margin: 0;
  background-color: #000000;
  font-family: "M PLUS Code Latin", monospace;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  color: #e1e1e1;
  overflow: hidden; 
}

a {
  text-decoration: none; 
  color: #0078A8;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li a {
  display: block;
  padding: 0.5rem;
  padding-top: 1rem;
  margin: 0;
  font-style: normal;
  color: #e1e1e1;
  text-decoration: none; 
  width: 100%;
}

p {
  letter-spacing: 1px;
  line-height: 1.5rem;
}

h1 {
  text-align: center;
}

h2{
  color: #616161;
}

.maincontent {
  display: flex;
  background-color: #000000;
  min-height: 100vh; 
  overflow-x: hidden;
  max-width: 100vw;
}

/*************************CONTENT****************************/
.content {
  padding-right: 4rem; 
  padding-left: 4rem;
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  min-height: auto; 
}

.content h1 {
  padding-top: 5rem;
  padding-bottom: 2rem;
}
/*************************SIDEBAR****************************/

.sidebar {
  height: auto;
  background-color: #1f1f1f;
  position: sticky;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

.sidebar h3 {
  text-align: center;
  overflow-wrap: break-word;
  width: -webkit-fill-available;
}

.collapse-button {
display: none;
}

@media (max-width: 600px) {
.maincontent {
    min-height: auto; 
  }

  .content {
    padding-right: 2rem; 
    padding-left: 2rem;
    padding-bottom: 3rem;
    width: 100%;
    position: static;
    min-height: auto; 
  }

  #home {
    display: block;
    padding: 1rem;
    padding-bottom: 4rem;
    padding-top: 0;
  }
  #about {
    display: block;
    padding: 1rem;
    padding-bottom: 4rem;
    padding-top: 0;
  }
  #cv {
    display: block;
    padding: 1rem;
    padding-bottom: 4rem;
    padding-top: 0;
  }

  .sidebar.open {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 3;
  }

  .sidebar.collapsed {
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 3;
  }
  
  .collapse-button {
    background-color: #444;
    color: #e1e1e1;
    border: none;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    display: block;
    position: sticky;
  }
  .collapse-button i {
    color: #e1e1e1;
  }
}

.myname {
  text-align: center;
  padding-top: 1rem;
}

.myname a{
  list-style-type: none;
  font-style: normal;
  text-decoration: none; 
  color: #f5f5f5;
}

.title {
  display: flex;
  width: 100%;
}
.title h3{
margin: 0;
padding-left: 1rem;
padding-right: 1rem;
}

.navsocial ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.navsocial li i:hover {
  color: #e1e1e1;
}

i{
  color: #616161;
}

.bluesky{
  height: 16px;
  width: 16px;
  fill: #616161;  

}

.bluesky:hover {
  fill: #e1e1e1; 
}
.navpages ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navpages li a {
  display: block;
  margin: 0;
  font-style: normal;
  color: white;
  text-decoration: none; 
  width: 100%;
  overflow-wrap: break-word;
  width: -webkit-fill-available;
}

.navpages li:hover {
   background-color: #616161;
}

/**************************HOME****************************/



/**************************DIY****************************/

.views {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  row-gap: 2rem;
  justify-content: center;
}

.imgcont {
  height: 25rem;
  width: 25rem;
}

  .views img {
    cursor: pointer;
    border-radius: 5px;
    margin: 10px;
    transition: transform 0.3s;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .views img:hover {
    transform: scale(1.03);
    box-shadow: 0 0 5px 2px   #e1e1e1;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    position: relative;
    color: #e1e1e1;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
  }
  .modal-content img {
    width: 100%;
    height: auto;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }


/**************************TAROT****************************/

.card-container {
  position: relative;
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 5rem; 
  width: 100%; 
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    align-content: center;
  }

  .card {
    width: auto;
    height: 25rem;
    transition: transform 1s ease;
    border-radius: 10px;
  }}

.card {
  width: auto;
  height: 25rem;
  transition: transform 1s ease;
  border-radius: 10px;
}

.back {
  z-index: 1; 
}
.front {
  z-index: 2;
}

.tarotcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: #1f1f1f;
  color: #e1e1e1;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  width: 10rem;
  position: relative; 
  overflow: hidden; 
  transition: transform 0.3s, box-shadow 0.3s;
}

.button:hover {
  transform: scale(1.03);
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -200%; 
  width: 100%; 
  height: 100%;
  background: linear-gradient(90deg, transparent, #e1e1e1, transparent);
  transform: skewX(-30deg); 
  transition: left 0.5s ease-in-out;
}

.button:hover::before {
  left: 100%; 
}

.attribute {
  position: static;
  margin-top: auto;
}



/********************* src/Clog.css ********************/


.clog-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.clog-post {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.clog-post h2 {
  margin: 0;
}

.clog-post p {
  line-height: 1.5;
}

/**************************CV****************************/

.skills {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  row-gap: 0rem;
}

.skills div{
  width: 30rem; 
}

/*********************ABOUT********************/
#about {
  display: block;
  padding-bottom: 3rem;
  padding-top: 0;
}

#about img {
  float: right; 
  width: 10rem; 
  margin: 0 0 1rem 1rem; 
  object-fit: cover;
  box-shadow: 5px 5px 50px rgba(255, 255, 255, 0.2); 
  background: linear-gradient(45deg, #616161, #e1e1e1); 
  padding: 2px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  #about img {
    display: block;
    margin: 0 auto 1rem auto; 
  }
  
}
